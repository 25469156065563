<template>
  <div class="inputBoxWrap">
    <label>{{item.label}}<br/>
    <input
      v-if="item.mask.length > 0"

      v-bind="$attrs"
      v-model.trim="value"
      v-on:blur="eventBlur"
      v-on:focus="eventFocus"
      v-mask="item.mask"
      :name="refs"
      :type="item.type"
      :placeholder="item.placeholder"
      v-bind:style="colorBorder"
    />
      <input
        v-else

        v-model.trim="value"
        v-on:blur="eventBlur"
        v-on:focus="eventFocus"
        :name="refs"
        :type="item.type"
        :placeholder="item.placeholder"
        v-bind:style="colorBorder"
      />
      <p class="errorMsg" v-if="statusVuex[refs].ShowError">{{errorMsg}}</p>
    </label>

  </div>
</template>

<script>
  /* todo suka blyat v-mask, сломал при пустой маске ввод любых символов
      нужна новая маска
  *   */
  import DataForInput from '@/store/Form/longFormOptions';
  import {mapGetters, mapState} from 'vuex'


  export default {
    inheritAttrs: false,
    name: "fmInput",
    data: function () {
      return {
        item: DataForInput[this.refs],
        isTimeToShowError:false
      }
    },
    props: {
      refs: {
        type: String,
        default: 'null'
      },
    },
    methods: {
      eventBlur: function () {
        this.$store.dispatch('errorShow', {
          target: this.refs,
          value: true,
        });
      },
      eventFocus: function(){
        this.$store.dispatch('errorShow', {
          target: this.refs,
          value: false,
        });
      }
    },
    watch: {

    },
    mounted() {

    },
    computed: {
      status :function(){
        return this.statusVuex[this.refs].code;
      },
      errorMsg :function(){
        return this.statusVuex[this.refs].msg;
      },
      showErrorMessage:function(){
        return this.status != 0 && this.isTimeToShowError;
      },
      colorBorder: function () {
        switch (this.status) {
          case 1:
            return 'border-color:#b7b7b7;';
          case 0:
            return 'border-color:green;';
          default:
            return 'border-color:red;';
        }
      },
      value:{
        get(){
          return this.valueVuex[this.refs]
        },
        set(value){
          let utiputi = {
            target:this.refs,
            value: value
          };
          this.$store.dispatch('saveSendData',utiputi);
        }
      },
      errorStatus:function () {
        return false
      },
      ...mapGetters(['getTargetFieldValue']),
      ...mapState({
        valueVuex: state => state.Form.sendData,
        statusVuex: state => state.Form.workData
      })
    }

  }
</script>

<style scoped>
.inputBoxWrap{background: #F6F8F7;padding: 3vw 3vw 2vw;border-radius: 1px;margin: 1vw 0;}
.inputBoxWrap label{display: block;color:#869A95;font-size:  4vw;}
.inputBoxWrap input{display: block;color:#222721;font-size: 5vw;padding: 1.6vw 0
;background: transparent;border: 0;width: 100%;}
.inputBoxWrap p{display: block;}

@media screen and (min-width: 760px) {
  .inputBoxWrap{margin:0 0 0.6vw;padding: 1.5vw 1.1vw 1vw;}
  .inputBoxWrap label{font-size: 1.1vw;}
  .inputBoxWrap input{font-size: 1.3vw;padding: 0.2vw 0;}
  .inputBoxWrap p{}
}
@media screen and (min-width: 1140px) {
  .inputBoxWrap{padding: 17px 13px 11px;margin-bottom: 7px;margin: 5px 0;}
  .inputBoxWrap label{font-size:  12px;}
  .inputBoxWrap input{font-size: 14px;padding: 2px 0;}
  .inputBoxWrap p{}
}
</style>
